import React from 'react';
import { useStyles } from './notFoundNextStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { showZopimDialog } from 'src/core/zopim';
import { useRouter } from 'next/router';

const NotFoundNext = () => {
  const classes = useStyles();
  const router = useRouter();

  const handleButtonClick = () => {
    router.push('/');
  };

  return (
    <Box className={classes.root}>
      <img className={classes.leftImg} src={'/images/404-left-img.svg'} />
      <Box className={classes.container}>
        <Typography className={classes.title}>
          <span className={classes.yellowTitle}>Oops! </span>
          Something went wrong!
        </Typography>
        <Box className={classes.imgContainerMobile}>
          <img
            className={classes.leftImgMobile}
            src={'/images/404-left-img.svg'}
          />
          <img
            className={classes.rightImgMobile}
            src={'/images/404-right-img.svg'}
          />
        </Box>
        <Typography className={classes.description}>
          You didn’t break the internet, but we can’t find what you are looking
          for. Perhaps our support agent could help you?
        </Typography>
        <Box className={classes.containerButton}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            className={classes.button}
            onClick={handleButtonClick}
          >
            <span className={classes.buttonTitle}>Back to Home</span>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            disableElevation
            className={classes.button}
            onClick={() => showZopimDialog()}
          >
            <span className={classes.buttonTitle}>Live Chat</span>
          </Button>
        </Box>
      </Box>
      <img className={classes.rightImg} src={'/images/404-right-img.svg'} />
    </Box>
  );
};
export default NotFoundNext;
