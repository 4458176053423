import React from 'react';
import Error from 'next/error';
import {useRouter} from 'next/router';
import {NextSeo} from 'next-seo';
import {ThemeProvider} from '@material-ui/core/styles';

import {HOUR} from 'src/core/utils';
import {themeNext} from 'src/themes/themeNext';

import PageTitle from '../src/components/PageTitle';
import {prepareInitialProps} from '../src/core/initialPropsSSP';
import {initializeApollo} from '../apollo/client';
import {PageQuery, PagesQuery} from '../src/components/Page/queries';
import {PageSlugs} from '../src/core/urls';
import NotFoundNext from '../src/views/NotFoundNext';
// import {PageNext} from '../src/componentsNext/PageNext';

import Loader from 'src/components/Loader';
import PageContent from 'src/components/PageContent';
// import BottomBanner from 'src/components/BottomBanner';
import Page from 'src/components/Page';
import {App} from 'src/components/App';

function RegularPage(props) {
  const router = useRouter();

  return (
    <App {...props}>
      <Page reviews={props.reviews}>
        {router.isFallback ? (
          <>
            <NextSeo noindex nofollow />
            <Loader />
          </>
        ) : props.page ? (
          <>
            <PageTitle page={props.page} />
            <PageContent page={props.page} reviews={props.reviews} />
          </>
        ) : (
          <>
            <NextSeo noindex nofollow />
            {/*<Error statusCode={404} title="Go to home page" />*/}
            <ThemeProvider theme={themeNext}>
              <NotFoundNext />
            </ThemeProvider>
          </>
        )}
        {/* <BottomBanner /> */}
      </Page>
    </App>
  );
}

// This function gets called at build time
export async function getStaticPaths() {
  const apolloClient = initializeApollo();
  const {data} = await apolloClient.query({
    query: PagesQuery
  });
  const paths = [];
  data.pages.edges.map(({node: page}) => {
    if (
      !Object.values(PageSlugs).includes(page.slug) ||
      PageSlugs.TERMS === page.slug
    ) {
      paths.push({
        params: {
          page: page.slug
        }
      });
    }
  });
  return {
    paths,
    fallback: true
  };
}

export async function getStaticProps(ctx) {
  const pageProps = await prepareInitialProps(ctx, {loadReviews: true});
  const apolloClient = initializeApollo();
  const {data: PageData} = await apolloClient.query({
    query: PageQuery,
    variables: {slug: ctx.params.page}
  });

  return {
    revalidate: HOUR,
    props: {
      ...pageProps,
      page: PageData.page
    }
  };
}
// export async function getServerSideProps(ctx) {
//   const lowerUrl = ctx.params.page.toLowerCase();
//   const OsrsPage = 'Buy-OSRS-Gold';
//   if (OsrsPage.toLowerCase() === lowerUrl) {
//     return {
//       redirect: {
//         destination: `/${OsrsPage}`,
//         statusCode: 301
//       }
//     };
//   }
//   if (lowerUrl !== ctx.params.page) {
//     return {
//       redirect: {
//         destination: `/${lowerUrl}`,
//         statusCode: 301
//       }
//     };
//   }
//   const pageProps = await prepareInitialProps(ctx);
//   const apolloClient = initializeApollo();
//   const { data: PageData } = await apolloClient.query({
//     query: PageQuery,
//     variables: { slug: ctx.params.page }
//   });
//   return {
//     props: {
//       ...pageProps,
//       page: PageData.page
//     }
//   };
// }

export default RegularPage;
