const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    height: '70vh',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.up(1440)]: {
      height: '80vh'
    },
    [theme.breakpoints.up(2040)]: {
      height: '90vh'
    },
    [theme.breakpoints.up(2840)]: {
      height: '100vh'
    }
  },
  leftImg: {
    marginBottom: 20,
    alignSelf: 'flex-end',
    width: 270,
    height: 190,
    [theme.breakpoints.down('md')]: {
      width: 135,
      height: 95
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  rightImg: {
    marginTop: 50,
    top: 20,
    width: 271,
    height: 295,
    [theme.breakpoints.down('md')]: {
      width: 135,
      height: 148
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  leftImgMobile: {
    width: 135,
    height: 95,
    alignSelf: 'flex-end'
  },
  rightImgMobile: {
    width: 135,
    height: 148,
    alignSelf: 'flex-start',
    marginBottom: 20
  },
  container: {
    maxWidth: 655,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      padding: '40px 0'
    }
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '64px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 24,
      lineHeight: '45px'
    }
  },
  yellowTitle: {
    color: theme.palette.secondary.main
  },
  description: {
    marginTop: 32,
    fontSize: 20,
    lineHeight: '32px',
    maxWidth: 655,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      margin: '0 16px'
    }
  },
  containerButton: {
    marginTop: 40,
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
      gap: 16,
      paddingBottom: 50
    }
  },
  button: {
    position: 'relative',
    height: 49,
    fontSize: 14,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      height: 45,
      fontSize: 12,
      width: 158
    }
  },
  buttonTitle: {
    position: 'relative',
    top: 1
  },
  imgContainerMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'space-between'
    }
  }
}));
